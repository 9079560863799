
































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";
import { AdminRouter } from "@/utils/routePathContsant";
import feedBackStore from "@/store/modules/feedback";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination
  }
})

 export default class NoticeClass extends Vue {
async created() {

}
 }
